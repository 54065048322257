import React from "react";
import useAutoFormField from "atom/autoform/useAutoFormField";
import Autocomplete from "molecule/autocomplete/Autocomplete";
import { AutocompleteItem } from "molecule/autocomplete/AutocompleteBloc";
import { AutocompleteComponentProps } from "molecule/autocomplete/Autocomplete";

function AutoFormAutocompleteField<T extends AutocompleteItem>(
  componentProps: AutocompleteComponentProps<T> & { name: string }
) {
  const [autoFormFieldProps] = useAutoFormField<AutocompleteComponentProps<T>>(
    componentProps,
    "autocomplete"
  );

  if (!autoFormFieldProps || !autoFormFieldProps.name) return null;

  return <Autocomplete {...componentProps} {...autoFormFieldProps} />;
}

export default AutoFormAutocompleteField;
